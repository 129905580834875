import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import { HTMLTable } from '@blueprintjs/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    {/* <iframe src="https://calendar.google.com/calendar/b/3/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FPhoenix&amp;src=cGFyc2VydG9uZ3VlLmNvbV9oMXJuc3N2bzNuNjlrMXVyOWhnbXV0bGkwa0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23F09300&amp;showPrint=1" style="border:solid 1px #777" width="800" height="600" frameborder="0" scrolling="no"></iframe> */}
    <p><a parentName="p" {...{
        "href": "https://d2l.arizona.edu",
        "target": "_self",
        "rel": "nofollow"
      }}>{`See the course D2L page for a complete class schedule`}</a>{`.`}</p>
    <h1 {...{
      "id": "class-preparation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#class-preparation",
        "aria-label": "class preparation permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Class Preparation`}</h1>
    <p>{`To prepare for each unit, complete the readings and activities noted in the table below.`}</p>
    <HTMLTable condensed striped mdxType="HTMLTable">
      <tbody>
        <tr>
            <td><strong>Unit</strong></td>
            <td><strong>Topic</strong></td>
            <td><strong>Readings &amp; Resources</strong></td>
        </tr>
        <tr>
            <td>
              <p>Getting started</p>
            </td>
            <td colSpan="1">
              <ul>
                <li>using Virtualbox to install a Linux VM</li>
                <li>version control with Git and GitHub</li>
                <li>Linux command line basics</li>
                <li>containers with Docker</li>
                <li>Jupyter</li>
              </ul>
            </td>
            <td colSpan="1">
              <p>
              <a href="https://parsertongue.org/courses/general/configuring-your-development-environment">Unit tutorials</a>
              </p>
            </td>
        </tr>
        <tr>
            <td>
              <p>Tokens and their attributes</p>
            </td>
            <td>
              <p>
              <ul>
                <li>tokens and selected attributes</li>
                <ul>
                  <li>part of speech tags</li>
                  <li>canonical word forms (lemmas)</li>
                  <li>named entity labels</li>
                </ul>
                <li>text normalization</li>
                <li>cross-linguistic differences in defining tokens and their attributes</li>
              </ul>
              </p>
            </td>
            <td>
            </td>
        </tr>
        <tr>
            <td>
              <p>Tokenization and regular expressions</p>
            </td>
            <td>
              <ul>
                <li>regular expressions (regexes)</li>
                <li>representing a state machine with a regular expression</li>
                <li>representing a regular expression as a state machine</li>
                <li>tokenization using patterns (regexes)</li>
              </ul>
            </td>
            <td>
            </td>
        </tr>
        <tr>
            <td>
              <p>Vector representations of words and documents</p>
            </td>
            <td>
              <ul>
                <li>feature engineering</li>
                <li>feature vectors</li>
                <li>feature vocabularies</li>
                <li><i>n</i>-grams (character-level and word-level)</li>
              </ul>
            </td>
            <td>
            </td>
        </tr>
        <tr>
            <td>
              <p>Probability basics</p>
            </td>
            <td>
              <ul>
                <li>the basics of probability</li>
                <ul>
                  <li>joint probabilities</li>
                  <li>conditional probabilities</li>
                  <li>marginal (simple) probabilities</li>
                </ul>
                <li>likelihood of sequences</li>
                <ul>
                  <li>language models</li>
                </ul>
                <li>probabilities in log space</li>
              </ul>
            </td>
            <td>
            </td>
        </tr>
        <tr>
            <td>
              <p>Comparing vectors</p>
            </td>
            <td>
              <ul>
                <li>direction and magnitude</li>
                <li>vector operations</li>
                <li>vector normalization (L2 norm)</li>
                <li>distance and similarity metrics</li>
                <ul>
                  <li>dot products</li>
                  <li>euclidean distance</li>
                  <li>cosine similarity</li>
                </ul>
                <li>centroids</li>
                <li>medoids</li>
              </ul>
            </td>
            <td>
            </td>
        </tr>
    </tbody>
    </HTMLTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      